@media (max-width: 1920px) {
  .Types {
    &__title {
      font-size: 46px;
    }
    &__body {
      width: 1180px;
      height: 600px;
      gap: 30px;
      .Types_accordeon {
        button {
          width: 400px;
        }
      }
      .Types__image_big {
        height: 500px;
      }
    }
  }

  .accordion {
    width: 400px;
  }
}

@media (max-width: 1650px) {
  .Types {
    &__title {
      font-size: 46px;
    }
    &__body {
      width: 1000px;
      height: 600px;
      gap: 30px;
      .Types_accordeon {
        button {
          width: 400px;
        }
      }
      .Types__image_big {
        height: 450px;
      }
    }
  }

  .accordion {
    width: 400px;
  }
}

@media (max-width: 1300px) {
  .Types {
    &__title {
      width: 700px;
      font-size: 40px;
    }
    &__text {
      width: 700px;
    }
    &__body {
      position: relative;
      width: 700px;
      height: 600px;
      gap: 30px;
      overflow: hidden;
      .Types_accordeon {
        position: relative;
        z-index: 1200;
        button {
          width: 400px;
        }
      }
      .Types__image_big {
        left: 0;
        top: 250px;
        filter: brightness(60%);
        height: 450px;
        position: absolute;
      }
    }
  }

  .accordion {
    width: 400px;
  }
}

@media (max-width: 800px) {
  .Types {
    &__title {
      width: auto;
      font-size: 36px;
      padding: 0px 40px;
    }
    &__text {
      width: auto;
      padding: 0px 40px;
    }
    &__body {
      position: relative;
      width: 500px;
      height: 600px;
      gap: 30px;
      overflow: hidden;
      .Types_accordeon {
        position: relative;
        z-index: 1200;
        button {
          width: 400px;
        }
      }
      .Types__image_big {
        top: 300px;
        filter: brightness(40%);
        height: 400px;
      }
    }
  }

  .accordion {
    width: 400px;
  }
}

@media (max-width: 600px) {
  .Types {
    &__title {
      width: auto;
      font-size: 26px;
      padding: 0px 20px;
    }
    &__text {
      width: auto;
      padding: 0px 40px;
    }
    &__body {
      position: relative;
      width: 350px;
      min-height: 500px;
      gap: 0px;
      overflow: hidden;
      .Types__image_big {
        top: 300px;
        height: 350px;
      }
    }
  }

  .accordion {
    width: 300px;
  }
}
