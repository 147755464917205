@media (max-width: 1920px) {
  .Main {
    min-height: 900px;
    &__container {
      width: 600px;
      .Main__title {
        font-size: 46px;
      }
      .Main__text {
        font-size: 20px;
        width: 450px;
      }
    }

    &__image {
      .Main__image_big {
        height: 450px;
      }
      .Main__image_small_container1,
      .Main__image_small_container2 {
        height: 450px;
      }
    }
  }
}

@media (max-width: 1650px) {
  .Main {
    min-height: 900px;
    &__container {
      width: 450px;
      .Main__title {
        font-size: 46px;
      }
      .Main__text {
        font-size: 20px;
        width: 350px;
      }
    }

    &__image {
      .Main__image_big {
        height: 400px;
      }
      .Main__image_small_container1,
      .Main__image_small_container2 {
        height: 400px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .Main {
    min-height: 1000px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    &__container {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 700px;
      .Main__title {
        font-size: 46px;
      }
      .Main__text {
        font-size: 20px;
        width: auto;
      }
    }

    &__image {
      .Main__image_big {
        height: 400px;
      }
      .Main__image_small_container1,
      .Main__image_small_container2 {
        height: 400px;
      }
    }
  }
}
@media (max-width: 1300px) {
  .Main {
    margin-top: 80px;
  }
}

@media (max-width: 800px) {
  .Main {
    gap: 80px;
    &__container {
      width: auto;
      padding: 0px 40px;
      .Main__title {
        font-size: 36px;
      }
      .Main__text {
        font-size: 20px;
        width: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .Main {
    gap: 60px;
    min-height: 900px;
    position: relative;
    overflow: hidden;
    &__container {
      width: auto;
      padding: 0px 40px;
      .Main__title {
        font-size: 36px;
      }
      .Main__text {
        font-size: 20px;
        width: auto;
      }
    }

    &__image {
      .Main__image_big {
        height: 350px;
      }
      .Main__image_small_container1,
      .Main__image_small_container2 {
        height: 350px;
        .Main__image_body {
          padding: 10px;
        }
      }
    }
  }
}
