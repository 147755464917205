.Header {
  z-index: 1500;
  height: calc(100vh - 60px);
  width: 250px;
  background-color: #1c1b20;
  position: fixed;
  z-index: 1500;
  padding: 30px 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  .Header__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 60px);
  }

  &__logo {
    border-radius: 10px;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__nav {
    .Header__nav_button {
      background-color: transparent;
      border: none;
      outline: none;
      color: white;
      display: flex;
      //   justify-content: center;
      align-items: center;
      gap: 5px;
      font-family: "Roboto Condensed", sans-serif;
      cursor: pointer;
      transition: 0.35s all;

      &:hover p {
        color: #ddfe55;
      }
      &:active {
        transform: translateY(8px);
      }

      p {
        font-size: 20px;
        transition: 0.35s all;
      }
    }
  }

  &__button {
    .Order_button {
      width: 200px;
      height: 50px;
      background-color: #ddfe55;
      border: none;
      outline: none;
      border-radius: 10px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      font-family: "Roboto Condensed", sans-serif;
      cursor: pointer;
    }
  }
}

.menu_closed {
  transition: 0.65s all;
  transform: translateX(120vw);
  opacity: 0;
}

.menu_opened {
  transition: 0.65s all;
  transform: translateX(0vw);
  opacity: 1;
}

.hamburger {
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  height: 3em;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

.light-container {
  backdrop-filter: blur(100px);
  filter: brightness(30%);
  z-index: 200;
}

.light {
  position: absolute;
  width: 0px;
  opacity: 0.75;
  background-color: white;
  box-shadow: #e9f1f1 0px 0px 20px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.x1 {
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.x2 {
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3 {
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  left: -15%;
}

.x4 {
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5 {
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6 {
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  left: -81%;
}

.x7 {
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8 {
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9 {
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

button:focus {
  outline: none;
}

@-webkit-keyframes floatUp {
  0% {
    top: 350vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -350vh;
    opacity: 0;
  }
}
@-moz-keyframes floatUp {
  0% {
    top: 350vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -350vh;
    opacity: 0;
  }
}
@-o-keyframes floatUp {
  0% {
    top: 350vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -350vh;
    opacity: 0;
  }
}
@keyframes floatUp {
  0% {
    top: 350vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -350vh;
    opacity: 0;
  }
}

.header {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  color: white;
  font-size: 2em;
}

#head1,
#head2,
#head3,
#head4,
#head5 {
  opacity: 0;
}

#head1 {
  -webkit-animation: fadeOut 1 5s ease-in;
  -moz-animation: fadeOut 1 5s ease-in;
  -o-animation: fadeOut 1 5s ease-in;
  animation: fadeOut 1 5s ease-in;
}

#head2 {
  -webkit-animation: fadeOut 1 5s ease-in;
  -moz-animation: fadeOut 1 5s ease-in;
  -o-animation: fadeOut 1 5s ease-in;
  animation: fadeOut 1 5s ease-in;
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  animation-delay: 6s;
}

#head3 {
  -webkit-animation: fadeOut 1 5s ease-in;
  -moz-animation: fadeOut 1 5s ease-in;
  -o-animation: fadeOut 1 5s ease-in;
  animation: fadeOut 1 5s ease-in;
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  animation-delay: 12s;
}

#head4 {
  -webkit-animation: fadeOut 1 5s ease-in;
  -moz-animation: fadeOut 1 5s ease-in;
  -o-animation: fadeOut 1 5s ease-in;
  animation: fadeOut 1 5s ease-in;
  -webkit-animation-delay: 17s;
  -moz-animation-delay: 17s;
  -o-animation-delay: 17s;
  animation-delay: 17s;
}

#head5 {
  -webkit-animation: finalFade 1 5s ease-in;
  -moz-animation: finalFade 1 5s ease-in;
  -o-animation: finalFade 1 5s ease-in;
  animation: finalFade 1 5s ease-in;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 22s;
  -moz-animation-delay: 22s;
  -o-animation-delay: 22s;
  animation-delay: 22s;
}
