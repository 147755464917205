.Hits {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0px;
  min-height: 900px;
  .Hits_body {
    width: 1030px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
.name {
  width: 250px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  position: relative;
  width: 330px;
  height: 500px;
  border-radius: 14px;
  z-index: 1111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 320px;
  height: 490px;
  z-index: 2;
  background: rgb(35, 35, 37, 0.95);
  backdrop-filter: blur(24px);
  border-radius: 10px;
  overflow: hidden;
  outline: 2px solid #232325;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.blob {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #ddfe55;
  opacity: 1;
  filter: blur(12px);
  animation: blob-bounce 5s infinite ease;
}

.hit_img {
  width: 200px;
  padding-bottom: 30px;
}

.btn_card {
  background-color: #ddfe55;
  color: #232325;
  border: none;
  outline: none;
  width: 200px;
  border-radius: 10px;
  height: 40px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.35s all;

  &:hover {
    scale: 1.05;
  }

  &:active {
    transform: translateY(10px);
  }
}

@keyframes blob-bounce {
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}
