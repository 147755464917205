.SmallInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  position: relative;
  &__body {
    width: 1000px;
    background-color: #232224;
    border-radius: 20px;
    padding: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    cursor: pointer;
    transition: 0.35s all;
    gap: 50px;
    .SmallInfo__Big {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 250px;
      h1 {
        margin: 0px;
        font-size: 40px;
        color: #efffae;
      }
      p {
        width: 120px;
        font-weight: 300;
      }
    }
  }
}
