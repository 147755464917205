@media (max-width: 1000px) {
  .Header {
    height: 60px;
    position: fixed;
    width: calc(100vw - 40px);
    z-index: 2500;
    padding: 10px 20px;
    background-color: #1c1b20;

    .Header__body {
      background-color: #1c1b20;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 60px;
    }

    &__logo {
      width: 180px;
      height: 50px;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      .Order_button {
        width: 200px;
        height: 50px;
      }
      .MenuButton {
        background-color: transparent;
        border: none;
        outline: none;
        height: 50px;
      }
    }
    &__burger_nav {
      height: 100vh;
      z-index: -1;
      background: radial-gradient(#262a16, #000000);
      width: 100vw;
      position: fixed;
      top: 80px;
      left: 0;
      .burger_nav_body {
        padding: 30px 0px;
        height: 85vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: 600px) {
  .Header {
    height: 60px;
    position: fixed;
    width: calc(100vw - 40px);
    z-index: 2500;
    padding: 10px 20px;
    background-color: #1c1b20;

    .Header__body {
      background-color: #1c1b20;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 60px;
    }

    &__logo {
      width: 180px;
      height: 50px;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      .Order_button {
        width: 150px;
        height: 40px;
        display: none;
      }
      .MenuButton {
        background-color: transparent;
        border: none;
        outline: none;
        height: 50px;
      }
    }
    &__burger_nav {
      height: 100vh;
      z-index: -1;
      background: radial-gradient(#262a16, #000000);
      width: 100vw;
      position: fixed;
      top: 80px;
      left: 0;
      .burger_nav_body {
        padding: 30px 0px;
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .Header__nav_button_order {
          .Order_nav_button {
            width: 200px;
            height: 50px;
            background-color: #ddfe55;
            border: none;
            outline: none;
            border-radius: 10px;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
            font-family: "Roboto Condensed", sans-serif;
            cursor: pointer;
          }
        }
      }
    }
  }
}
