.Main {
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  position: relative;

  &__container {
    width: 700px;
    .Main__title {
      font-size: 56px;
      margin: 20px 0px;
    }
    .Main__text {
      font-size: 22px;
      font-weight: 400;
      color: rgb(200, 200, 200);
      width: 550px;
    }
    .Main__button {
      .Main__button_img {
        width: 20px;
        height: 20px;
        background-image: url("../../public/battery-0-svgrepo-com.svg");
        animation: 1s battery infinite linear;
      }
      width: 350px;
      height: 50px;
      background-color: #ddfe55;
      border: none;
      outline: none;
      border-radius: 10px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      font-family: "Roboto Condensed", sans-serif;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transition: 0.35s all;

      &:hover {
        scale: 1.05;
      }
      &:active {
        transform: translateY(8px);
      }
    }
  }

  &__image {
    display: flex;
    gap: 10px;
    .Main__image_big {
      height: 500px;
    }
    .Main__image_small_container1,
    .Main__image_small_container2 {
      height: 500px;
      .Main__image_body {
        background-color: #232224;
        border-radius: 20px;
        padding: 20px;
        overflow: hidden;
        animation: 8s imageFlying infinite linear;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        cursor: pointer;
        transition: 0.35s all;

        &:hover {
          scale: 1.05;
        }
        .Main__image_small {
          height: 120px;
        }
        p {
          margin: 5px 0px;
          font-weight: 300;
        }
        h5 {
          margin: 5px 0px;
          font-weight: 300;
          color: #ddfe55;
        }
      }
    }
    .Main__image_small_container1 {
      display: flex;
      align-items: end;
    }
  }
}

@keyframes battery {
  0% {
    background-image: url("../../public/battery-0-svgrepo-com.svg");
  }
  20% {
    background-image: url("../../public/battery-1-svgrepo-com.svg");
  }
  40% {
    background-image: url("../../public/battery-2-svgrepo-com.svg");
  }
  60% {
    background-image: url("../../public/battery-3-svgrepo-com.svg");
  }
  80% {
    background-image: url("../../public/battery-4-svgrepo-com.svg");
  }
  100% {
    background-image: url("../../public/battery-5-svgrepo-com.svg");
  }
}

@keyframes imageFlying {
  0% {
    transform: translateY(10px);
  }
  25% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}
